<template>
  <div>
    <BackButton name="Audits" route="/audits" />
    <transition name="slide-fade">
      <div v-if="this.audit" id="findings">
        <div>
          <h2 class="page-title">
            {{ audit.audit_name }}
          </h2>
          <div
            :key="componentKey"
            id="dropdown"
            style="float: right; margin-top: 10px; margin-bottom: -15px"
          >
            <b-button
              variant="outline-dark"
              class="mr-2"
              @click="report"
              v-if="audit.audit_report"
            >
              <b-icon-file-earmark-text class="mr-2" />View audit report
            </b-button>
            <b-button variant="outline-dark" class="mr-2" @click="loadData()"
              ><b-icon-arrow-clockwise></b-icon-arrow-clockwise
            ></b-button>
          </div>
          <div class="audit-details" style="display: block">
            <div style="float: left; transition: all 0.5s" class="mt-2 mb-1">
              <p class="mr-3" style="float: left">
                <b-icon
                  :icon="findingStatusIcon(this.audit.audit_closed).icon"
                  :variant="findingStatusIcon(this.audit.audit_closed).variant"
                  class="mr-1"
                ></b-icon>
                {{ audit.audit_closed ? "Audit closed " : "Audit open" }}
              </p>
              <p class="mr-3" style="float: left">
                <b-icon-circle aria-hidden="true"></b-icon-circle>
                {{ audit.closedFindings.aggregate.count }} /
                {{ audit.totalFindings.aggregate.count }} findings closed
              </p>

              <p class="mr-3" style="float: left">
                <b-icon-calendar-fill
                  aria-hidden="true"
                  style="color: dodgerblue"
                ></b-icon-calendar-fill>
                {{
                  audit.audit_expiry_date
                    ? `Expires ${this.$options.filters.formatDate(
                        audit.audit_expiry_date
                      )}`
                    : "No expiry date set"
                }}
              </p>
            </div>
          </div>
        </div>

        <FindingsTable :findings="findings" @data-updated="loadData" />
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import BackButton from "../components/BackButton.vue";
import FindingsTable from "../components/FindingsTable.vue";
export default {
  components: {
    BackButton,
    FindingsTable,
  },
  data() {
    return {
      fill: { gradient: ["red", "green", "blue"] },
      findingCategories: [],
      companies: [],
      selectedFinding: undefined,
      audit: undefined,
      auditId: undefined,
      findings: undefined,
      findingsGrouped: [],
      tableIsLoading: true,
      files: [],
      messages: [],
      newMessage: undefined,
      componentKey: 0,
      dataKey: 0,
      uploadURL: undefined,
      editAudit: {
        audit_name: undefined,
        audit_closed: undefined,
        audit_expiry_date: undefined,
        audit_auditee_company: {
          id: "00000000-0000-0000-0000-000000000000",
          company_name: undefined,
        },
        audit_client_company: {
          id: "00000000-0000-0000-0000-000000000000",
          company_name: undefined,
        },
        auditee_can_see_audit_report: undefined,
      },
      newFinding: {
        name: "",
        dueDate: undefined,
        reference: "",
        isClosed: "false",
        category: "",
        rbf: false,
      },
    };
  },
  computed: {
    auditReportStatusIcon() {
      return this.audit.audit_report
        ? {
            icon: "check-circle-fill",
            variant: "success",
          }
        : {
            icon: "exclamation-circle-fill",
            variant: "danger",
          };
    },
    closeAuditText() {
      const openFindings = this.findings.filter(
        (finding) => finding.finding_closed == false
      );
      const openFindingsCount = openFindings.length;
      return openFindingsCount === 0
        ? "All findings are closed. You're good to go!"
        : openFindingsCount === 1
        ? "There is still 1 open finding. Are you sure you want to close this audit?"
        : `There are still ${openFindingsCount} open findings. Are you sure you want to close this audit?`;
    },
  },
  methods: {
    findingStatusIcon(status) {
      return status
        ? {
            icon: "check-circle-fill",
            variant: "success",
          }
        : {
            icon: "exclamation-circle-fill",
            variant: "danger",
          };
    },
    async loadData() {
      const auditIdParam = this.$route.params.auditId;
      this.auditId = auditIdParam;
      const token = await this.$auth.getTokenSilently();
      const auditId = this.auditId;
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `query {
  findings(where: {finding_audit_id: {_eq: "${auditId}"}}) {
    id
    finding_reference
    finding_name
    finding_closed
    finding_closed_date
    finding_category
    finding_audit_id
    finding_due_date
  }
  audits_by_pk(id: "${auditId}") {
    audit_name
    audit_closed
    audit_expiry_date
    audit_report {
      s3_key
      updated_at
    }
    totalFindings: audit_findings_aggregate {
      aggregate {
        count
      }
    }
    closedFindings: audit_findings_aggregate(where: {_and: {finding_closed: {_eq: true}}}) {
      aggregate {
        count
      }
    }
  }
}

`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.findingsGrouped = [];
          this.findingsFormatted = [];
          this.findings = response.data.data.findings;
          this.audit = response.data.data.audits_by_pk;
          this.findingsGrouped = this.findings.reduce(function (acc, item) {
            if (acc[item.finding_category]) {
              acc[item.finding_category] = [
                ...acc[item.finding_category],
                item,
              ];
            } else {
              acc[item.finding_category] = [item];
            }
            return acc;
          }, {});
          this.reloadComponent();
          this.formatFindings();
        });
    },
    formatFindings() {
      for (const [key, value] of Object.entries(this.findingsGrouped)) {
        key === "Resolve before flight"
          ? this.findingsFormatted.unshift({
              mode: "span",
              label: key,
              html: false,
              children: value,
            })
          : this.findingsFormatted.push({
              mode: "span",
              label: key,
              html: false,
              children: value,
            });
      }
    },
    reloadComponent() {
      this.componentKey += 1;
    },
    async report() {
      var windowRef = window.open();
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            key: this.audit.audit_report.s3_key,
          },
          url: "https://api.skyswarm.sky360.com.au/v1/audit-report/download",
        })
        .then((response) => {
          if (response.status === 200) {
            windowRef.location.href = response.data.downloadURL;
            windowRef.document.title = "SkySwarm – Audit report";
          } else {
            this.$vs.notification({
              flat: false,
              color: "danger",
              position: "bottom-right",
              duration: "8000",
              title: "Uh oh! Something bad has happened...",
              text: "An error occured while getting the audit report",
            });
          }
        });
    },
    sendNotification(color, title, text, duration) {
      this.$vs.notification({
        color: color,
        duration: duration ? duration : 8000,
        title: title,
        text: text,
      });
    },
  },
  created: async function () {
    this.loadData();
  },
};
</script>

<style>
.vgt-inner-wrap {
  box-shadow: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.dropdown-item:active {
  background-color: #343a40;
  color: white !important;
}
.dropdown-item:active.text-danger {
  background-color: red;
  color: white !important;
}
.audit-details .b-icon {
  margin-right: 0.25rem;
}
</style>
