<template>
  <div>
    <vue-good-table
      :rows="findingsGrouped"
      :columns="columns"
      styleClass="vgt-table"
      :group-options="{
        enabled: true,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'finding_name', type: 'asc' },
      }"
      ><div slot="emptystate">No findings found</div>

      <template slot="table-header-row" slot-scope="props">
        <span
          :style="
            props.row.label === 'Resolve before flight'
              ? 'color: var(--danger);'
              : ''
          "
        >
          {{ props.row.label }}
        </span>
      </template>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'finding_closed'">
          <b-icon
            :icon="findingStatusIcon(props.row.finding_closed).icon"
            :variant="props.row.finding_closed ? 'success' : 'danger'"
            class="mr-1"
          ></b-icon>
          {{
            props.row.finding_closed && props.row.finding_closed_date
              ? `Closed
                ${$options.filters.formatDate(props.row.finding_closed_date)}`
              : props.row.finding_closed
              ? "Closed"
              : "Open "
          }}
        </span>
        <span v-else-if="props.column.field == 'actions-column'">
          <div>
            <b-button
              right
              variant="outline-dark"
              size="sm"
              class="mr-2"
              v-b-modal.modal-finding-attachments
              @click="findingAttachmentsClicked(props.row)"
            >
              <b-icon-paperclip />
            </b-button>
            <b-button
              right
              variant="outline-dark"
              size="sm"
              v-b-modal.modal-finding-activity
              @click="findingActivityClicked(props.row)"
            >
              <b-icon-chat-right-text />
            </b-button>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <MessagesModal :finding="selectedFinding" />
    <AttachmentsModal :finding="selectedFinding" />
  </div>
</template>

<script>
import MessagesModal from "../components/MessagesModal";
import AttachmentsModal from "../components/AttachmentsModal";
export default {
  name: "FindingsTable",
  components: {
    MessagesModal,
    AttachmentsModal,
  },
  props: {
    findings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      findingsGrouped: [],
      columns: [
        {
          field: "finding_name",
          label: "Name",
          sortable: false,
        },
        {
          field: "finding_reference",
          sortable: false,
          label: "Reference",
        },
        {
          field: "finding_closed",
          label: "Status",
          sortable: false,
          width: "100px",
        },
        {
          field: "finding_due_date",
          label: "Due date",
          sortable: false,
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd/MM/yyyy",
          width: "120px",
        },
        {
          field: "actions-column",
          key: "open",
          label: "",
          sortable: false,
          width: "110px",
        },
      ],
      selectedFinding: {},
    };
  },
  watch: {
    findings: function () {
      this.groupFindings();
    },
  },
  created() {
    this.groupFindings();
  },
  methods: {
    groupFindings() {
      this.findingsGrouped = [];
      const step1 = this.findings.reduce(function (acc, item) {
        if (acc[item.finding_category]) {
          acc[item.finding_category] = [...acc[item.finding_category], item];
        } else {
          acc[item.finding_category] = [item];
        }
        return acc;
      }, {});

      for (const [key, value] of Object.entries(step1)) {
        if (key !== "Resolve before flight") {
          this.findingsGrouped.push({
            mode: "span",
            label: key,
            html: false,
            children: value,
          });
        }
      }
      this.findingsGrouped.sort();
      for (const [key, value] of Object.entries(step1)) {
        if (key === "Resolve before flight") {
          this.findingsGrouped.unshift({
            mode: "span",
            label: key,
            html: false,
            children: value,
          });
        }
      }
    },
    findingStatusIcon(status) {
      return status
        ? {
            icon: "check-circle-fill",
            variant: "success",
          }
        : {
            icon: "exclamation-circle-fill",
            variant: "danger",
          };
    },
    async findingActivityClicked(finding) {
      this.selectedFinding = finding;
    },
    async findingAttachmentsClicked(finding) {
      this.selectedFinding = finding;
    },
  },
};
</script>