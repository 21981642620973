var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"rows":_vm.findingsGrouped,"columns":_vm.columns,"styleClass":"vgt-table","group-options":{
      enabled: true,
    },"sort-options":{
      enabled: true,
      initialSortBy: { field: 'finding_name', type: 'asc' },
    }},scopedSlots:_vm._u([{key:"table-header-row",fn:function(props){return [_c('span',{style:(props.row.label === 'Resolve before flight'
            ? 'color: var(--danger);'
            : '')},[_vm._v(" "+_vm._s(props.row.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'finding_closed')?_c('span',[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":_vm.findingStatusIcon(props.row.finding_closed).icon,"variant":props.row.finding_closed ? 'success' : 'danger'}}),_vm._v(" "+_vm._s(props.row.finding_closed && props.row.finding_closed_date ? ("Closed " + (_vm.$options.filters.formatDate(props.row.finding_closed_date))) : props.row.finding_closed ? "Closed" : "Open ")+" ")],1):(props.column.field == 'actions-column')?_c('span',[_c('div',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-finding-attachments",modifiers:{"modal-finding-attachments":true}}],staticClass:"mr-2",attrs:{"right":"","variant":"outline-dark","size":"sm"},on:{"click":function($event){return _vm.findingAttachmentsClicked(props.row)}}},[_c('b-icon-paperclip')],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-finding-activity",modifiers:{"modal-finding-activity":true}}],attrs:{"right":"","variant":"outline-dark","size":"sm"},on:{"click":function($event){return _vm.findingActivityClicked(props.row)}}},[_c('b-icon-chat-right-text')],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("No findings found")])]),_c('MessagesModal',{attrs:{"finding":_vm.selectedFinding}}),_c('AttachmentsModal',{attrs:{"finding":_vm.selectedFinding}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }