var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-finding-activity","title":_vm.finding.finding_name,"ok-variant":"dark","cancel-variant":"outline-dark","scrollable":""},on:{"show":_vm.getMessages},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-form-textarea',{class:_vm.messageInputClass,staticStyle:{"float":"left","width":"360px"},attrs:{"placeholder":"Enter a message...","rows":"1","max-rows":"6"},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}}),_c('b-dropdown',{staticClass:"float-right",attrs:{"split":"","right":"","text":"Send","variant":"outline-dark"},on:{"click":_vm.sendMessage}},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-send-attachment",modifiers:{"modal-send-attachment":true}}]},[_vm._v("Send file...")])],1),_c('b-modal',{attrs:{"id":"modal-send-attachment","title":"Send file","ok-variant":"dark","cancel-variant":"outline-dark","ok-title":"Send"},on:{"ok":_vm.sendAttachment}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.attachment),callback:function ($$v) {_vm.attachment=$$v},expression:"attachment"}})],1)],1)]},proxy:true}])},[_c('div',{attrs:{"id":"messages"}},_vm._l((_vm.messages),function(message){return _c('div',{key:message.id},[(!message.message_is_note)?_c('p',{class:_vm.messageSenderClass(message.message_sender_user)},[_vm._v(" "+_vm._s(message.message_sender_user.user_name)+" "+_vm._s(message.message_sender_user.user_company_name !== null ? " – " + message.message_sender_user.user_company_name : "")+" ")]):_vm._e(),_c('div',{class:[
            _vm.messageBubbleClass(message) + ' ' + _vm.messagePaddingClass(message) ]},[(
              !['png', 'jpg', 'jpeg'].includes(
                message.message_content.split('.').pop()
              )
            )?_c('span',{staticStyle:{"padding":"0","margin":"0"}},[(message.message_attachment)?_c('b-icon',{staticStyle:{"width":"max-content","padding-right":"5px","max-width":"25px"},attrs:{"icon":_vm.fileIcon(message.message_content),"aria-hidden":"true"}}):_vm._e(),(message.message_attachment)?_c('b-link',{style:(_vm.linkStyle(message)),on:{"click":function($event){return _vm.downloadFile(message.message_attachment.s3_key)}}},[_vm._v(_vm._s(message.message_content))]):_c('span',[_vm._v(" "+_vm._s(message.message_content)+" ")])],1):_vm._e(),(
              ['png', 'jpg', 'jpeg'].includes(
                message.message_content.split('.').pop()
              )
            )?_c('img',{staticStyle:{"max-width":"250px","max-height":"250px","padding":"-10px !important","border-radius":"0.25rem"},attrs:{"src":message.message_attachment.url}}):_vm._e()])])}),0)]),_c('b-modal',{attrs:{"id":"modal-delete-message","title":"Delete message?","ok-variant":"danger","cancel-variant":"outline-dark","ok-title":"Delete message","size":"sm"},on:{"ok":function($event){return _vm.deleteMessage(_vm.selectedMessage.item)},"hide":function($event){_vm.selectedMessage = undefined}}},[(_vm.selectedMessage)?_c('div',[_c('p',[_vm._v(" Are you sure you want to delete this message? This can't be undone. ")]),_c('div',{class:[
          _vm.messageBubbleClass(_vm.selectedMessage.item) +
            ' ' +
            _vm.messagePaddingClass(_vm.selectedMessage.item) ]},[(
            !['png', 'jpg', 'jpeg'].includes(
              _vm.selectedMessage.item.message_content.split('.').pop()
            )
          )?_c('span',{staticStyle:{"padding":"0","margin":"0"}},[(_vm.selectedMessage.item.message_attachment)?_c('b-icon',{staticStyle:{"width":"max-content","padding-right":"5px","max-width":"25px"},attrs:{"icon":_vm.fileIcon(_vm.selectedMessage.item.message_content),"aria-hidden":"true"}}):_vm._e(),(_vm.selectedMessage.item.message_attachment)?_c('b-link',{style:(_vm.linkStyle(_vm.selectedMessage.item)),on:{"click":function($event){return _vm.downloadFile(_vm.selectedMessage.item.message_attachment.s3_key)}}},[_vm._v(_vm._s(_vm.selectedMessage.item.message_content))]):_c('span',[_vm._v(" "+_vm._s(_vm.selectedMessage.item.message_content)+" ")])],1):_vm._e(),(
            ['png', 'jpg', 'jpeg'].includes(
              _vm.selectedMessage.item.message_content.split('.').pop()
            )
          )?_c('img',{staticStyle:{"max-width":"250px","max-height":"250px","padding":"-10px !important","border-radius":"0.25rem"},attrs:{"src":_vm.selectedMessage.item.message_attachment.url}}):_vm._e()])]):_vm._e()]),_c('b-modal',{attrs:{"id":"modal-edit-message","title":"Edit message","ok-variant":"dark","cancel-variant":"outline-dark","ok-title":"Update message","size":"sm"},on:{"ok":function($event){return _vm.updateMessage(_vm.selectedMessage.item)},"hide":function($event){_vm.selectedMessage = undefined}}},[(_vm.selectedMessage)?_c('div',[(!_vm.selectedMessage.item.message_attachment)?_c('div',[_c('b-form-textarea',{staticStyle:{"float":"left"},attrs:{"placeholder":"Enter a message...","rows":"2","max-rows":"6"},model:{value:(_vm.selectedMessage.item.message_content),callback:function ($$v) {_vm.$set(_vm.selectedMessage.item, "message_content", $$v)},expression:"selectedMessage.item.message_content"}})],1):_c('div',[_vm._v("Attachments can't be edited.")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }