<template>
  <div>
    <b-modal
      id="modal-finding-activity"
      :title="finding.finding_name"
      ok-variant="dark"
      cancel-variant="outline-dark"
      scrollable
      @show="getMessages"
    >
      <!-- <template #modal-header="{ close }">
      <h5>{{ finding.finding_name }}</h5>
      <b-button size="sm" variant="link" style="color: black" @click="close()">
        <b-icon-x />
      </b-button>
    </template> -->
      <div id="messages">
        <div v-for="message in messages" :key="message.id">
          <p
            v-if="!message.message_is_note"
            :class="messageSenderClass(message.message_sender_user)"
          >
            {{ message.message_sender_user.user_name }}
            {{
              message.message_sender_user.user_company_name !== null
                ? " – " + message.message_sender_user.user_company_name
                : ""
            }}
          </p>

          <div
            :class="[
              messageBubbleClass(message) + ' ' + messagePaddingClass(message),
            ]"
          >
            <span
              style="padding: 0; margin: 0"
              v-if="
                !['png', 'jpg', 'jpeg'].includes(
                  message.message_content.split('.').pop()
                )
              "
            >
              <b-icon
                style="width: max-content; padding-right: 5px; max-width: 25px"
                v-if="message.message_attachment"
                :icon="fileIcon(message.message_content)"
                aria-hidden="true"
              ></b-icon>

              <b-link
                v-if="message.message_attachment"
                :style="linkStyle(message)"
                @click="downloadFile(message.message_attachment.s3_key)"
                >{{ message.message_content }}</b-link
              >
              <span v-else>
                {{ message.message_content }}
              </span>
            </span>
            <img
              v-if="
                ['png', 'jpg', 'jpeg'].includes(
                  message.message_content.split('.').pop()
                )
              "
              :src="message.message_attachment.url"
              style="
                max-width: 250px;
                max-height: 250px;
                padding: -10px !important;
                border-radius: 0.25rem;
              "
            />
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-form-textarea
            v-model="newMessage"
            placeholder="Enter a message..."
            rows="1"
            max-rows="6"
            style="float: left; width: 360px"
            :class="messageInputClass"
          ></b-form-textarea>
          <b-dropdown
            split
            right
            text="Send"
            @click="sendMessage"
            variant="outline-dark"
            class="float-right"
          >
            <b-dropdown-item v-b-modal.modal-send-attachment
              >Send file...</b-dropdown-item
            >
          </b-dropdown>

          <b-modal
            id="modal-send-attachment"
            title="Send file"
            ok-variant="dark"
            cancel-variant="outline-dark"
            ok-title="Send"
            @ok="sendAttachment"
          >
            <b-form-file
              v-model="attachment"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-modal>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-delete-message"
      title="Delete message?"
      ok-variant="danger"
      cancel-variant="outline-dark"
      ok-title="Delete message"
      size="sm"
      @ok="deleteMessage(selectedMessage.item)"
      @hide="selectedMessage = undefined"
    >
      <div v-if="selectedMessage">
        <p>
          Are you sure you want to delete this message? This can't be undone.
        </p>
        <div
          :class="[
            messageBubbleClass(selectedMessage.item) +
              ' ' +
              messagePaddingClass(selectedMessage.item),
          ]"
        >
          <span
            style="padding: 0; margin: 0"
            v-if="
              !['png', 'jpg', 'jpeg'].includes(
                selectedMessage.item.message_content.split('.').pop()
              )
            "
          >
            <b-icon
              style="width: max-content; padding-right: 5px; max-width: 25px"
              v-if="selectedMessage.item.message_attachment"
              :icon="fileIcon(selectedMessage.item.message_content)"
              aria-hidden="true"
            ></b-icon>

            <b-link
              v-if="selectedMessage.item.message_attachment"
              :style="linkStyle(selectedMessage.item)"
              @click="
                downloadFile(selectedMessage.item.message_attachment.s3_key)
              "
              >{{ selectedMessage.item.message_content }}</b-link
            >
            <span v-else>
              {{ selectedMessage.item.message_content }}
            </span>
          </span>
          <img
            v-if="
              ['png', 'jpg', 'jpeg'].includes(
                selectedMessage.item.message_content.split('.').pop()
              )
            "
            :src="selectedMessage.item.message_attachment.url"
            style="
              max-width: 250px;
              max-height: 250px;
              padding: -10px !important;
              border-radius: 0.25rem;
            "
          />
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-edit-message"
      title="Edit message"
      ok-variant="dark"
      cancel-variant="outline-dark"
      ok-title="Update message"
      size="sm"
      @ok="updateMessage(selectedMessage.item)"
      @hide="selectedMessage = undefined"
    >
      <div v-if="selectedMessage">
        <div v-if="!selectedMessage.item.message_attachment">
          <b-form-textarea
            v-model="selectedMessage.item.message_content"
            placeholder="Enter a message..."
            rows="2"
            max-rows="6"
            style="float: left"
          ></b-form-textarea>
        </div>
        <div v-else>Attachments can't be edited.</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
// import gql from "graphql-tag";
// const MESSAGES_SUBSCRIPTION = gql`
//   subscription messages($message_finding_id: uuid!) {
//     messages(
//       where: { message_finding_id: { _eq: $message_finding_id } }
//       order_by: { timestamp: asc }
//     ) {
//       id
//       message_content
//       message_is_note
//       message_attachment {
//         id
//         s3_key
//       }
//       message_sender_user {
//         id
//         user_name
//       }
//     }
//   }
// `;
export default {
  name: "MessagesModal",
  props: {
    finding: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imagesCount: 0,
      messages: [],
      testdata: undefined,
      newMessage: "",
      attachment: undefined,
      selectedMessage: undefined,
      options: [
        {
          name: "Delete message",
          class: "delete-message",
        },
      ],
    };
  },
  // apollo: {
  //   $subscribe: {
  //     messages: {
  //       query: MESSAGES_SUBSCRIPTION,
  //       variables() {
  //         return {
  //           message_finding_id: this.finding.id,
  //         };
  //       },
  //       result(data) {
  //         this.messages = data.data.messages;
  //       },
  //     },
  //   },
  // },
  computed: {
    messageInputClass() {
      if (this.newMessage.length < 40 && !this.newMessage.includes("\n")) {
        return "short";
      } else {
        return "";
      }
    },
  },
  methods: {
    clearFinding() {},
    async downloadFile(key) {
      var windowRef = window.open();
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            key: key.replace(/\+/g, " "),
          },
          url: "https://api.skyswarm.sky360.com.au/v1/attachments/download",
        })
        .then((response) => {
          windowRef.location.href = response.data.downloadURL;
          if (response.status === 200) {
            this.$vs.notification({
              color: "success",
              duration: "5000",
              text: "Downloading attachment...",
            });
          } else {
            this.$vs.notification({
              color: "danger",
              progress: "auto",
              duration: "8000",
              title: "Uh oh! Something bad has happened...",
              text: "An error occured while downloading the attachment",
            });
          }
        });
    },
    async sendMessage() {
      const token = await this.$auth.getTokenSilently();
      const message = this.newMessage;
      const finding = this.finding;
      if (message !== "") {
        axios
          .request({
            method: "post",
            url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
            data: {
              query: `mutation sendMessage {
  insert_messages_one(object: {message_finding_id: "${
    finding.id
  }", message_content: "${message.trim()}"}) {
    message_content
  }
}`,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (
              response.data.data.insert_messages_one.message_content === message
            ) {
              this.getMessages(this.findingActivityModalID);
              this.newMessage = "";
            } else {
              this.$vs.notification({
                flat: false,
                color: "danger",
                position: "bottom-right",
                duration: "none",
                title: "Uh oh! Something bad has happened...",
                text: `An error occured while sending this message.`,
              });
            }
          });
      }
    },
    async sendAttachment() {
      const attachment = this.attachment;
      const type = this.attachment.type;
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "x-amz-meta-sender": this.$auth.user[
              `https://skyswarm.sky360.com.au/id`
            ].substring(6),
            "x-amz-meta-name": attachment.name,
            "x-amz-meta-size": `${attachment.size} bytes`,
            "x-amz-meta-finding": this.finding.id,
            "Content-Type": type,
          },
          data: {
            attachment,
          },
          url: "https://api.skyswarm.sky360.com.au/v1/attachments/upload",
        })
        .then((response) => {
          const uploadURL = response.data.uploadURL;
          const key = response.data.key;
          const config = {
            headers: {
              key: key,
              "x-amz-meta-sender": this.$auth.user[
                `https://skyswarm.sky360.com.au/id`
              ].substring(6),
              "x-amz-meta-name": attachment.name,
              "x-amz-meta-size": `${attachment.size} bytes`,
              "x-amz-meta-finding": this.finding.id,
              "Content-Type": type,
            },
          };
          return axios.put(uploadURL, attachment, config);
        })
        .then((response) => {
          if (response.status == 200) {
            this.sendNotification(
              "success",
              "File sent!",
              `"${attachment.name}" was successfully sent.`
            );
            this.attachment = undefined;
          } else {
            this.sendNotification(
              "danger",
              "Uh oh! Something bad happened...",
              `An error occured while trying to upload "${attachment.name}"`
            );
          }
          setTimeout(() => {
            this.getMessages();
          }, 3000);
        });
    },

    // displaySender(index) {
    //   const realindex = index
    //   console.log(this.messages[])
    //   // return this.messages[realindex-1].message_sender_user.id !== this.$auth.user[`https://skyswarm.sky360.com.au/id`] ? false : true
    // },
    fileIcon(file) {
      let extension = file.split(".").pop();
      switch (extension) {
        case "pdf":
          return "file-earmark-text-fill";
        case "doc":
        case "docx":
          return "file-earmark-word-fill";
        case "jpg":
        case "jpeg":
        case "png":
        case "heic":
          return "image-fill";

        default:
          return "file-earmark-fill";
      }
    },
    sendNotification(color, title, text, duration) {
      this.$vs.notification({
        color: color,
        duration: duration ? duration : 8000,
        title: title,
        text: text,
      });
    },
    linkStyle(message) {
      return message.message_sender_user.id ===
        this.$auth.user[`https://skyswarm.sky360.com.au/id`]
        ? "color: white;"
        : "color: #2c3e50";
    },
    messageBubbleClass(message) {
      return message.message_is_note
        ? "message-note"
        : message.message_sender_user.id ===
          this.$auth.user[`https://skyswarm.sky360.com.au/id`]
        ? "message-from-me"
        : "message-not-from-me";
    },
    messagePaddingClass(message) {
      return !["png", "jpg", "jpeg"].includes(
        message.message_content.split(".").pop()
      )
        ? "p-2"
        : "";
    },
    messageSenderClass(sender) {
      return sender.id === this.$auth.user[`https://skyswarm.sky360.com.au/id`]
        ? "message-sender-from-me"
        : "message-sender-not-from-me";
    },
    async getMessages() {
      if (this.finding) {
        const token = await this.$auth.getTokenSilently();
        axios
          .request({
            method: "post",
            url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
            data: {
              query: `query messages {
  messages(where: {message_finding_id: {_eq: "${this.finding.id}"}}, order_by: {timestamp: asc}) {
    id
    message_content
    message_is_note
    message_attachment {
      id
      s3_key
    }
    message_sender_user {
      id
      user_name
      user_company_name
    }
  }
}

`,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.errors) {
              this.sendNotification(
                "danger",
                "Uh oh! Something bad has happened...",
                `An error occured loading messages".
              <br><br>
              ${response.data.errors[0].message}`
              );
              return;
            } else {
              if (response.data.data.messages.length === 0) {
                this.messages = [
                  {
                    id: "000000000",
                    message_content: "No messages",
                    message_is_note: true,
                    message_attachment: null,
                    message_sender_user: null,
                  },
                ];
              } else {
                this.messages = response.data.data.messages;
              }
              this.getImages();
            }
          });
      }
    },
    async getImages() {
      // const messagesWithImageAttachments = this.messages
      //   .filter((message) => message.message_attachment)
      //   .filter((message) =>
      //     ["png", "jpg", "jpeg"].includes(
      //       message.message_attachment.s3_key.split(".").pop()
      //     )
      //   );
      // console.log(
      //   "New messages with img attachments: ",
      //   messagesWithImageAttachments.length,
      //   " Old messages w/ img attachments: ",
      //   this.imagesCount
      // );
      const token = await this.$auth.getTokenSilently();
      this.imagesCount = 0;
      this.messages.forEach((message, index) => {
        if (message.message_attachment) {
          if (
            ["png", "jpg", "jpeg"].includes(
              message.message_attachment.s3_key.split(".").pop()
            )
          ) {
            axios
              .request({
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                  key: message.message_attachment.s3_key.replace(/\+/g, " "),
                },
                url:
                  "https://api.skyswarm.sky360.com.au/v1/attachments/download",
              })
              .then((response) => {
                this.messages[index].message_attachment = {
                  id: message.message_attachment.id,
                  s3_key: message.message_attachment.s3_key,
                  url: response.data.downloadURL,
                };
              });
          }
        }
      });
    },
  },
  watch: {
    finding: function () {
      this.getMessages();
    },
  },
};
</script>

<style>
.message-from-me {
  position: relative;
  margin-left: auto;
  background-color: dodgerblue;
  width: max-content;
  max-width: 250px;
  height: max-content;
  text-align: right;
  color: white;
  border-radius: 0.25rem;
}
.message-not-from-me {
  position: relative;
  margin-right: auto;
  background-color: #ebebeb;
  width: max-content;
  max-width: 250px;
  height: max-content;
  text-align: left;
  border-radius: 0.25rem;
}
.message-note {
  position: relative;
  margin-top: 10px;
  padding: 10px;
  width: max-content;
  max-width: 300px;
  height: max-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  opacity: 75%;
  font-size: 85%;
}
.message-sender-from-me {
  position: relative;
  width: max-content;
  max-width: 300px;
  height: max-content;
  text-align: center;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  color: black;
  font-size: 85%;
}
.message-sender-not-from-me {
  position: relative;
  width: max-content;
  max-width: 300px;
  height: max-content;
  text-align: center;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  color: black;
  font-size: 85%;
}
#modal-finding-activity .modal-dialog-scrollable .modal-body {
  flex-direction: column-reverse;
  display: flex;
}
.short {
  max-height: 38px;
}
.modal-dialog-scrollable .modal-content {
  overflow: initial !important;
}
.delete-message {
  color: var(--danger) !important;
}
.delete-message:hover {
  background-color: var(--danger);
  color: white !important;
}
</style>